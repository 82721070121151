export { RequestError } from 'request-promise/errors';

export class NotFoundError {
  constructor(resourceName = 'Resource') {
    this.name    = this.constructor.name;
    this.stack   = (new Error).stack;
    this.message = `${resourceName} not found`;
  }
};

export class BadRequestError {
  constructor(data) {
    this.data = data;
    this.name    = this.constructor.name;
    this.stack   = (new Error).stack;
    this.message = `Bad request: ${JSON.stringify(this.data)}`;
  }
};

export class ForbiddenError {
  constructor(data) {
    this.data = data;
    this.name    = this.constructor.name;
    this.stack   = (new Error).stack;
    this.message = `Forbidden: ${JSON.stringify(this.data)}`;
  }
};

export class PreconditionFailed {
  constructor(data) {
    this.data = data;
    this.name    = this.constructor.name;
    this.stack   = (new Error).stack;
    this.message = `Forbidden: ${JSON.stringify(this.data)}`;
  }
};

NotFoundError.prototype.__proto__      = Error.prototype;
ForbiddenError.prototype.__proto__     = Error.prototype;
BadRequestError.prototype.__proto__    = Error.prototype;
PreconditionFailed.prototype.__proto__ = Error.prototype;
